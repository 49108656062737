.header {
  transition: all 200ms linear;
  font-family: $font-family-heading;
  background-color: var(--color-base-bg);
  padding: 10px 0;
  color: var(--color-primary);
  .container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: $header-height;
    .logos {
      margin-right: auto;
    }
  }
  a {
    color: var(--color-primary);
    font-size :20px;
  }
}
.header-transparent {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
  background-color: transparent !important;
  box-shadow: none !important;
  color: white;
  a {
    color: white !important;
  }
  li.active a {
    color: var(--color-header-link-active) !important;
  }
  .logos {
    .logo-desktop {
      display: none;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
    .logo-mobile {
      display: none;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
    .logo-desktop-invert {
      display: none;
      @include media-breakpoint-up(sm) {
        display: flex;
      }
    }
    .logo-mobile-invert {
      display: flex;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }
  .hamburger {
    color: white;
  }
}
.header-scrolled {
  transition: all 200ms linear;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
  color: var(--color-primary);
  a {
    color: var(--color-header-link);
  }
}
.header-fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  a {
    color: var(--color-header-link);
  }
}
